import classNames from "classnames";
import { Product } from "~/services/product";
import { formatNumber } from "~/utils/number";
import styles from "./ProductItem.module.scss";
import Skeleton from "react-loading-skeleton";

const ProductPrice = ({
  data,
  loading,
}: {
  data: Partial<Product>;
  loading?: boolean;
}) => {
  if (!data.price_range?.minimum_price?.final_price && !!loading) {
    return (
      <div className="flex justify-between w-full gap-1">
        <Skeleton containerClassName="flex-1" />
        <Skeleton containerClassName="flex-1 text-right" />
      </div>
    )
  }

  if (!data.price_range?.minimum_price?.final_price) {
    return null;
  }

  return (
    <div className="flex flex-1 flex-wrap items-center md:gap-1">
      <span className={classNames("text-primary lg:text-lg leading-[18px]", styles.price)}>
        {data.price_range?.minimum_price?.final_price?.value && (
          <>
            {data.price_range?.minimum_price?.final_price?.symbol || "$"}
            {formatNumber(
              data.price_range?.minimum_price?.final_price?.value,
              2
            )}
            +
          </>
        )}
      </span>
      {!!data.price_range?.minimum_price?.discount?.percent_off && (
        <div className={"text-grey-400"}>
          <span className="line-through text-xs lg:text-sm">
            $
            {formatNumber(
              data.price_range?.minimum_price?.regular_price?.value,
              2
            )}
          </span>
          {!!data.price_range?.minimum_price?.discount?.percent_off && (
            <span
              className={classNames(styles.discount, "text-xs lg:text-sm")}
            >{`(${data.price_range?.minimum_price?.discount?.percent_off}% off)`}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductPrice;
